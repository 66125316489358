@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:ital,wght@0,300;0,400;0,600;1,400&display=swap');

@import './scss/mixins';
@import './scss/global';
@import './components/utils/FileUploader.scss';
@import './routes/Muestreo.scss';
// @import './scss/navbar';
// @import './scss/files';
// @import './scss/questions';
// @import './scss/card';

// Posestratificación
// @import './scss/posestratificación/global-pos';

.app-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Make the container take the full height of the viewport */
}

.amplify-authenticator {
  max-width: 400px; /* You can adjust this to control the width */
  margin: auto;
}
